import {
  bodyFont,
  box,
  colors,
  Column,
  Container,
  Divider,
  Icon,
  icons,
  respondTo,
} from '@123-front/ui-kit';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const ContactBox = styled.address({
  ...box,
  ...bodyFont('sm'),
  fontStyle: 'normal',
  width: '100%',
  padding: '12px 16px',
  marginTop: 32,
  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    [respondTo('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    li: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 12px',
    },
    hr: {
      [respondTo('lg')]: { display: 'none' },
    },
  },
});

const IconContainer = styled.span({ width: 24, marginRight: 8 });

const Contact: React.FC = () => (
  <ContactBox>
    <ul>
      <li>
        <IconContainer>
          <Icon icon={icons.whatsapp} size="md" />
        </IconContainer>
        <a href="https://wa.me/56938628428">+56 9 3862 8428</a>
      </li>
      <li>
        <IconContainer>
          <Icon icon={icons.headset} size="md" />
        </IconContainer>
        <a href="tel:800-123-787">800-123-787</a>
      </li>
      <Divider />
      <li>
        <IconContainer>
          <Icon icon={icons.address} size="md" />
        </IconContainer>
        Suecia 155, Providencia (Región Metropolitana, Chile)
      </li>
    </ul>
  </ContactBox>
);

const FooterContainer = styled.footer({
  backgroundColor: colors.gray.ultralight,
  [respondTo('md')]: { paddingBottom: 24 },
});
const TopDivider = styled.hr({
  margin: 0,
  border: 0,
  borderTop: `1px solid ${colors.gray.lighten}`,
});
const LegalText = styled.p({ ...bodyFont('xs'), textAlign: 'center' });

interface FooterProps {
  text?: string;
}

const ChileFooter: React.FC<FooterProps> = ({ text }) => {
  const badges = useStaticQuery(graphql`
    query Badges {
      first: file(name: { eq: "logo_cmf" }) {
        childImageSharp {
          fixed(height: 28) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      second: file(name: { eq: "logo_certisur-geotrust" }) {
        childImageSharp {
          fixed(height: 28) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <FooterContainer>
      <TopDivider />
      <Container>
        <Column desktop={12} desktop={12}>
          <Contact />
          <div style={{ textAlign: 'center', margin: '28px 0 12px 0' }}>
            <a
              href="https://www.cmfchile.cl/portal/principal/605/w3-channel.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                style={{ margin: '4px 16px' }}
                {...badges.first.childImageSharp}
                alt="Comisión para el mercado financiero"
              ></Img>
              <Img
                style={{ margin: '4px 16px' }}
                {...badges.second.childImageSharp}
                alt="Geotrust - Certisur"
              ></Img>
            </a>
          </div>
          <LegalText style={{ marginTop: 24 }}>{text}</LegalText>
        </Column>
      </Container>
    </FooterContainer>
  );
};

ChileFooter.defaultProps = {
  text: '123 Corredores de seguros SpA | RUT nº  76815712 | Matrícula nº 8425',
};

export default ChileFooter;
