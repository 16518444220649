import {
  bodyFont,
  Button,
  colorTheme,
  Column,
  Container,
  Divider,
  respondTo,
  Row,
} from '@123-front/ui-kit';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import React from 'react';
import ChileFooter from '../../../../components/chile-footer';
import FlexCenter from '../../../../components/flex-center';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import Subtitle from '../../../../components/subtitle';
import Title from '../../../../components/title';

const noFollowMetaTag = [
  {
    name: 'robots',
    content: 'noindex,nofollow',
  }
];

const BodyText = styled.p({
  ...bodyFont('lg'),
  color: colorTheme.mute,
  marginBottom: 56,
  [respondTo('xs', 'lg')]: {
    ...bodyFont('md'),
    color: colorTheme.mute,
    textAlign: 'center',
    marginLeft: 21,
    marginRight: 21,
  },
  strong: {
    fontWeight: 'normal',
  },
});
const SoapTitle = styled(Title)({
  marginBottom: 30,
  [respondTo('xs', 'lg')]: { textAlign: 'center', marginBottom: 24 },
});
const SoapSubtitle = styled(Subtitle)({ [respondTo('xs', 'lg')]: { textAlign: 'center' } });
const DesktopDivider = styled(Divider)({
  marginBottom: 32,
  [respondTo('xs', 'lg')]: { display: 'none' },
});
const HeroDecoration = styled.img({
  position: 'absolute',
  top: 210,
  zIndex: -1,
  [respondTo('xs', 'lg')]: {
    display: 'none',
  },
});

interface SoapLandingData {
  heroDots: {
    publicURL: string;
  };
  largeHeroImage: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
  smallHeroImage: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

const soapChile: React.FC<PageProps<SoapLandingData>> = ({ data }) => {
  return (
    <Layout gtmId="GTM-P2PPCL8" footerComponent={<ChileFooter />}>
      <SEO
        title="Cotizar SOAP. Seguro Obligatorio de Accidentes Personales"
        siteTitle="123Seguro Chile"
        description="En 123Seguro podrás Cotizar SOAP (Seguro Obligatorio de Accidentes Personales) en pocos pasos, de una manera rápida y sencilla. ¡Contrata SOAP aquí!"
        meta={noFollowMetaTag}
      />
      <HeroDecoration src={data.heroDots.publicURL} />
      <Container>
        <Column>
          <Row>
            <Column desktop="5">
              <SoapTitle style={{ color: colorTheme.mute }}>SOAP</SoapTitle>
              <DesktopDivider />
            </Column>
          </Row>
          <Row>
            <Column desktop="5">
              <SoapSubtitle>Realiza aquí tu Cotización SOAP</SoapSubtitle>
              <BodyText>
                En 123Seguro podrás contratar tu{' '}
                <strong>Seguro Obligatorio de Accidentes Personales</strong> de manera rápida y
                sencilla
              </BodyText>
              <Column desktop="10">
                <Row internal="false">
                  <Column>
                    <a
                      style={{ display: 'block' }}
                      href="https://www.segurossura.cl/SoapVenta/Cotizador/enviaPaso?rut=76815712&idConvenio=123SegurosMarzo2021"
                    >
                      <h2 style={{ margin: 0 }}>
                        <Button block style={{ marginBottom: 16 }} size="lg">
                          Cotizar SOAP
                        </Button>
                      </h2>
                    </a>
                    <a
                      style={{ display: 'block' }}
                      href="https://www.123seguro.cl/seguros/automotriz/cotizar/marca"
                    >
                      <h2 style={{ margin: 0 }}>
                        <Button block style={{ marginBottom: 62 }} variant="secondary" size="lg">
                          Seguro Automotriz
                        </Button>
                      </h2>
                    </a>
                  </Column>
                </Row>
              </Column>
            </Column>
            <Column desktop="7">
              <FlexCenter>
                <Img
                  style={{
                    marginBottom: 32,
                  }}
                  fixed={[
                    { ...data.largeHeroImage.childImageSharp.fixed, media: `(min-width: 768px)` },
                    { ...data.smallHeroImage.childImageSharp.fixed, media: `(max-width: 768px)` },
                  ]}
                  alt="SOAP"
                  title="SOAP"
                ></Img>
              </FlexCenter>
            </Column>
          </Row>
        </Column>
      </Container>
    </Layout>
  );
};

export const images = graphql`
  query {
    heroDots: file(relativePath: { eq: "pattern-dots-hero.svg" }) {
      publicURL
    }
    largeHeroImage: file(name: { eq: "photo_soap_landing-ads_hero_large" }) {
      childImageSharp {
        fixed(width: 511) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    smallHeroImage: file(name: { eq: "photo_soap_landing-ads_hero_small" }) {
      childImageSharp {
        fixed(width: 328) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default soapChile;
